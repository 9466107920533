$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * navigation
	// *
	// *
    $(".js-nav-btn").click(function(){
        $(".js-nav-btn").toggleClass("active");
        $(".js-nav").toggleClass("active");
        $("html, body").toggleClass("freeze");
        $(".js-nav-mobile").toggleClass("active");
        $(".js-header-banking").toggle();
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * navSub
	// *
	// *
    $(".js-nav-item-parent > a").click(function(event){

        // prevent tab from redirecting to url unless tab is active
		if (!$(this).parent("li").hasClass("open")) {
			event.preventDefault();
		}

        $(this).parent("li").toggleClass("open");
    });

    $(".js-nav-item-parent").mouseleave(function() {
        $(this).removeClass("open");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 200; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements(){
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sticky header
    // *
    // *
    $(window).scroll(function() {
		if($(window).scrollTop() >= 120) {
			$(".js-header-sticky").addClass("active");
		}else {
			$(".js-header-sticky").removeClass("active");
		}
	});



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * carousel
    // *
    // *
    var $carousel = $(".js-carousel");

    if ($carousel.length){
        $carousel.each(function(){
            $(this).slick({
                infinite: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                fade: false,
                dots: true,
                arrows: true,
                prevArrow: $(this).parents(".js-carousel-wrapper").find(".js-carousel-nav-prev"),
                nextArrow: $(this).parents(".js-carousel-wrapper").find(".js-carousel-nav-next"),
                speed: 1000,
                responsive: [
                    {
                        breakpoint: 1000,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 700,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false
                        }
                    }
                ]
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * carousel opinion
    // *
    // *
    var $carousel = $(".js-carousel-opinion");

    if ($carousel.length){
        $carousel.each(function(){
            $(this).slick({
                infinite: false,
                slidesToShow: 4,
                fade: false,
                dots: true,
                arrows: true,
                appendDots: $(this).parents(".js-carousel-opinion-wrapper").find(".js-slider-nav-dots"),
                prevArrow: $(this).parents(".js-carousel-opinion-wrapper").find(".js-carousel-nav-prev"),
                nextArrow: $(this).parents(".js-carousel-opinion-wrapper").find(".js-carousel-nav-next"),
                speed: 1000,
                responsive: [
                    {
                        breakpoint: 1000,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * slider
    // *
    // *
    var $slider = $(".js-slider");

    if ($slider.length){
        $slider.each(function(){
            $(this).slick({
                infinite: false,
                fade: false,
                dots: true,
                arrows: true,
                appendDots: $(this).parents(".js-slider-wrapper").find(".js-slider-nav-dots"),
                prevArrow: $(this).parents(".js-slider-wrapper").find(".js-slider-nav-prev"),
                nextArrow: $(this).parents(".js-slider-wrapper").find(".js-slider-nav-next"),
                speed: 1000
            });

        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * imageTextSlider
    // *
    // *
    var $imageTextSlider = $(".js-image-text-slider");

    if ($imageTextSlider.length){
        $imageTextSlider.each(function(){
            $(this).slick({
                fade: false,
                infinite: true,
                dots: true,
                arrows: true,
                prevArrow: $(this).parents(".js-image-text-slider-wrapper").find(".js-image-text-slider-nav-prev"),
                nextArrow: $(this).parents(".js-image-text-slider-wrapper").find(".js-image-text-slider-nav-next"),
                speed: 1000,
                responsive: [
                    {
                        breakpoint: 700,
                        settings: {
                            arrows: false
                        }
                    }
                ]
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * fancybox
    // *
    // *
    $('[data-fancybox]').fancybox({});


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollNext
    // *
    // *
    $(".js-scroll-next").click(function(){
        var $next = $(this).parents(".js-scroll-next-wrapper").next();
        scrollTo($next);
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *  tabs
    // *
    // *
    if ($(".js-tabs-button").length) {

        $(".js-tabs-button").click(function(){
            var index = $(this).index();
            $(this).addClass("active").siblings().removeClass("active");
            $(".js-tabs-content:nth-child(" + (index + 1) + ")").show().siblings().hide();
            $(".js-tabs-text-button:nth-child(1)").trigger("click");
            window.location.hash = $(this).data("hash");
        });

        $(".js-tabs-text-button").click(function(){
            var index = $(this).index();
            $(this).addClass("active").siblings().removeClass("active");
            $(".js-tabs-text:nth-child(" + (index + 1) + ")").show().siblings().hide();
        });

        if(window.location.hash) {
            var hash = window.location.hash.replace('#','');
            $(".js-tabs-button[data-hash='" + hash + "']").trigger("click");
        } else {
            $(".js-tabs-button:nth-child(1)").trigger("click");
        }

        $(window).on('hashchange', function( e ) {
            var hash = window.location.hash.replace('#','');
            $(".js-tabs-button[data-hash='" + hash + "']").trigger("click");
        });
    }











    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *  text-tabs
    // *
    // *
    $(".js-text-tabs-button").click(function(){
        var index = $(this).index();
        $(this).addClass("active").siblings().removeClass("active");
        $(this).parents(".js-text-tabs-wrapper").find(".js-text-tabs-content:nth-child(" + (index + 1) + ")").show().siblings().hide();
    });

    $(".js-text-tabs-button:nth-child(1)").trigger("click");


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *  top-tabs
    // *
    // *
    if( $(".js-top-tabs-wrapper").length ) {
        $(".js-tabs-button").click(function(){
            var index = $(this).index();
            $(this).addClass("active").siblings().removeClass("active");
            $(this).parents(".js-top-tabs-wrapper").find(".js-top-tabs-content:nth-child(" + (index + 1) + ")").show().siblings().hide();
        });

        $(".js-tabs-button:nth-child(1)").trigger("click");
    }








    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * searchOverlay
    // *
    // *
    $(".js-search-btn").click(function(){
        $(".js-search-overlay").toggleClass("active");
        $("html, body").toggleClass("freeze");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * form - input files
    // *
    // *
    $(".js-input-file").on('change', function(){
        var title = $(this).val().replace(/^.*[\\\/]/, '');
        $(this).parents(".js-input-file-wrapper").find(".js-input-file-label").html(title);
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav mobile
    // *
    // *
    $(".js-nav-mobile-icon").click(function(){
        $(this).parents(".parent").toggleClass("open").find(".js-nav-mobile-inner").slideToggle();
    });

    // trigger active menü-parents
    $('.js-nav-mobile .parent.active > div .js-nav-mobile-icon').trigger("click");



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * ie11 Fix SVG + opbject fit
    // *
    // *
    svg4everybody();
    objectFitImages();



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hide content on success
    // *
    // *
    if ($(".js-toggle-success-message").length) {
        $(".js-toggle-success-content").hide();
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * popup
    // *
    // *
    if ($(".js-popup").length) {
        var popup = sessionStorage.getItem("popup"),
            delay = $(".js-popup").data("delay");

        if (popup != 1) {
            $(".js-popup").delay(delay).fadeIn();
        }

        $(".js-popup-button").click(function(){
            $(".js-popup").fadeOut();
            sessionStorage.setItem("popup",1);
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * download
    // *
    // *
    $(".js-download-button").click(function(){
        $(this).parents(".js-download-parent").toggleClass("active");
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderTeaser
    // *
    // *
    var $sliderTeaser = $(".js-sliderteaser");

    if ($sliderTeaser.length) {
        $sliderTeaser.each(function(){
            $(this).slick({
                infinite: false,
                fade: false,
                dots: false,
                arrows: true,
                autoplay: false,
                variableWidth: true,
                prevArrow: $(this).parents(".js-sliderteaser-wrapper").find(".js-sliderteaser-prev"),
                nextArrow: $(this).parents(".js-sliderteaser-wrapper").find(".js-sliderteaser-next"),
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderTeam
    // *
    // *
    var $sliderTeam = $(".js-sliderteam");

    if ($sliderTeam.length) {
        $sliderTeam.each(function(){

            var $sliderImage = $(this),
                $sliderWrapper = $(this).parents(".js-sliderteam-wrapper");
                $sliderText = $(this).parents(".js-sliderteam-wrapper").find(".js-sliderteam-text");


            $sliderImage.slick({
                infinite: false,
                fade: false,
                dots: false,
                arrows: true,
                autoplay: false,
                variableWidth: true,
                prevArrow: $sliderWrapper.find(".js-sliderteam-prev"),
                nextArrow: $sliderWrapper.find(".js-sliderteam-next"),
                asNavFor: $sliderText
            });

            $sliderText.slick({
                infinite: false,
                fade: true,
                dots: false,
                arrows: false,
                autoplay: false,
                variableWidth: false,
                asNavFor: $sliderImage
            });

            // toggle filter
            $(".js-sliderteam-open").click(function() {
                $sliderWrapper.find(".js-sliderteam-toggle").slideToggle();
            })

            // filtering
            $(".js-sliderteam-filter").click(function() {
                $(".js-sliderteam-filter").removeClass("active");
                $(this).addClass("active");
                var filter = $(this).data("filter");
                $sliderImage.slick('slickUnfilter');
                $sliderText.slick('slickUnfilter');
                $sliderImage.slick('slickFilter', '.' + filter);
                $sliderText.slick('slickFilter', '.' + filter);
                $sliderWrapper.find(".js-sliderteam-toggle").slideUp();

                $sliderImage.slick('slickGoTo', 0);
            })

            // On after slide change, pause videos and play active video
            $sliderImage.on('afterChange', function(event, slick, currentSlide, nextSlide){
                $(".js-sliderteam-video").each(function() {
                    $(this).get(0).currentTime = 0;
                    $(this).get(0).pause();
                });
                $(this).find(".slick-slide").eq(currentSlide).find(".js-sliderteam-video").get(0).play();
            });
        });
    }

    // play video once in viewport
    var videoPlayed = 0;

    $(window).scroll(function() {
        if ($(".js-sliderteam-video").hasClass("animateIn--active") && videoPlayed == 0) {
            $(".js-sliderteam-video.animateIn--active").get(0).play();
            videoPlayed = 1;
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-button").click(function(){
        $(this).parents(".js-accordion-item").toggleClass("active").siblings().removeClass("active");
    });

});
